<!--
 * @Author: your name
 * @Date: 2021-05-10 20:27:50
 * @LastEditTime: 2021-05-11 17:09:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\dingyuelist\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation msg="订阅列表">
    </navigation>
    <div>
      <div @click="todingyuemsg(item)" v-for="(item, index) in listmsg" v-bind:key="index" class="dyc">
        <div>订阅主题</div>
        <div ><div>{{item.theme?item.theme:item.jop }}</div><div :style="item.status==1?'background-color:#088ffa':'background-color:rgb(255, 95, 25)'" class="menu2">{{ item.status==1?'开启':item.status==2?'关闭':'' }}</div></div>
        <div>
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <div>
          {{ item.industry }} {{item.area==''?'':(item.area+'').split("/")[0]+'/'+(item.area+'').split("/")[1] }} {{ item.education_background }}
          {{!item.years_working?'':item.years_working=="0-0"?"":item.years_working.split("-")[0]=='0'?'应届':item.years_working.split("-")[0]}}{{!item.years_working?'':item.years_working=="0-0"?"应届":item.years_working.split("-")[1]=='0'?'-应届':'-'+item.years_working.split("-")[1]}} {{ item.salary }}
        </div>
        <div>{{item.add_time}}</div>
      </div>
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="nomsgclass"></div>
  </div>
</template>
<script>
import loading from "../../components/loading2/index";
export default {
  components: {
    loading,
  },
  data() {
    return {
      menuindex: 0,
      pageno: 0,
      listmsg: "",
      selenum:0
    };
  },
  methods: {
    filtermeth() {
      this.$refs.filterid.showfilter();
    },
    todingyuemsg(item){
      this.$router.push({
        path:"/dingyuelistmsg",
        query:{
          id:btoa(item.id)
        }
      })
    },
    loaddom() {
      this.clickmenu();
    },
    clickmenu() {
      this.getmsg("/firm/v1/Resume/subscribeLists", {
        pageno: ++this.pageno,
        reqType: "resume",
        limit: 15,
        type: 1, //1-开启 2-关闭 不传查询全部
      });
    },
    getmsg(url, msg) {
      this.$refs.loades.loadhttpes(url, msg).then((res) => {
         
        this.listmsg = res.arraymsg;
      });
    },
  },
};
</script>
<style scoped>
.menu2{
  font-weight: 500;
  font-size: .2rem;
  margin-left: .2rem;
  background-color: #088ffa;
  padding: .03rem .2rem;
  color: white;
  border-radius: .05rem;
  line-height: .3rem;
}
.tiaojian {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  border-radius: 50%;
  background-color: #ff5f19;
  color: #ffffff;
  font-size: 0.2rem;
  top: -0.1rem;
  right: -0.1rem;
}
.nomsgclass {
  height: 1.5rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.dyc {
  padding: 0.28rem 0;
  margin: 0 0.4rem;
  border-bottom-color: #d6d6d6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.33rem;
}
.dyc > :first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
  margin-bottom: 0.15rem;
}
.dyc > :nth-child(2) {
  font-size: 0.34rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #232323;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
}
.dyc > :nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dyc > :nth-child(4) {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #232323;
  line-height: 0.48rem;
}
.dyc > :nth-child(5) {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
  margin-top: 0.21rem;
}
.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
.sem {
  color: #222222;
  font-weight: bold;
}
.sh1 {
  display: flex;
  align-items: center;

  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b6b6b6;
  line-height: 0.48rem;
}
.sh1 > div {
  margin-right: 0.54rem;
}
.daohang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.27rem 0.35rem;
  background-color: white;
}
.addding {
  display: flex;
  align-items: center;
}
.addding > :nth-child(2) {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  margin-left: 0.12rem;
}
</style>